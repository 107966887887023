import React from "react";
import Hero from "./Hero";
import GoldmedalMoment from "./GoldmedalMoment";


function Main() {
  return (
    <div>
      <Hero />
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-12">
              <div className="blog-classic">
    
                <GoldmedalMoment/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
