import React from "react";
import Hero from "./Hero";
import Blog1 from "./Blog1";

function Main() {
  return (
    <div>
      <Hero />
      <Blog1/>
    </div>
  );
}

export default Main;
