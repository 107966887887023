import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  // const [email, setEmail] = useState("");

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const url = "https://apis.andrewhaley.ca/mails/newsletterMail";
  //   const options = {
  //     method: "POST",
  //     headers: { "content-type": "application/x-www-form-urlencoded" },
  //     body: new URLSearchParams({ email: email }),
  //   };

  //   try {
  //     const response = await fetch(url, options);
  //     if ((response.ok)) {
  //       toast.success("Subscribed successfully!");
  //       setEmail("");
  //     } else {
  //       toast.error("Fail to Subscribe... Try Again Later!");
  //     }
  //   } catch (error) {
  //     toast.error("Fail to Subscribe... Try Again Later!");
  //   }
  // };

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };
  return (
    <footer className="main-footer" id="footer">
      <div className="auto-container">
        <div className="widgets-section">
          <div className="row clearfix">
            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget logo-widget">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="../assets/images/logo.png"
                          width={150}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="text">
                      My commitment is to work with you and exceed your
                      expectations. In addition, provide your audience with
                      tools so they too can reach their own gold medal moment.
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-4 col-md-6 col-sm-12 offset-md-2">
                  <div className="footer-widget links-widget">
                    <h5>Pages</h5>
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-6 col-sm-12">
                        <ul className="list-link">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/#about">About</a>
                          </li>
                          <li>
                            <a href="#/">Blog </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                <div className="footer-column col-lg-6 col-md-6 col-sm-12 ">
                  <div className="footer-widget links-widget">
                    <h5>Get In Touch</h5>
                    <div className="row clearfix">
                      <div className="column col-lg-6 col-md-6 col-sm-12">
                        <ul className="list-link">
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                          <li>
                            <a href="tel:+(437) 925 2001">(437) 925 2001</a>
                          </li>
                          <li>
                            <a href="#/">andrew_haley@hotmail.com </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="footer-column col-lg-6 col-md-6 col-sm-12 ">
                  <div className="footer-widget newsletter-widget">
                    <h5>Newsletter</h5>
                    <div className="newsletter-form">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <span className="icon flaticon-email"></span>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email address"
                            required
                          />
                          <button
                            type="submit"
                            className="theme-btn submit-btn"
                          >
                            Subscribe Now
                            <span className="arrow flaticon-right-arrow-1"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="clearfix">
            <div className="text-center">
              <div className="copyright">
                2024 &copy; Andrew Haley - All Rights Reserved by {""}
                <a
                  href="https://codeflamme.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <strong>Code Flamme</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />
    </footer>
  );
}

export default Footer;
