import React from 'react'
import Hero from './Hero'
import About from './About'
import Service from './Service'
import Course from './Course'
import Process from './Process'
import Featured from './Featured'
import Testmonial from './Testmonial'
import News from './News'
import Sidebar from './Sidebar'

function Main() {
  return (
    <div>
      <Sidebar/>
      <Hero/>
      <Service/>
      <About/>
      <Course/>
      <Featured/>
      <Process/>
      <Testmonial/>
      <News/>
    </div>
  )
}

export default Main
