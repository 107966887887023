import React from "react";
import { Link } from "react-router-dom";

function Process() {
  return (
    <div id="speaking">
      <section
        className="process-section"
        style={{
          backgroundImage: 'url("assets/images/background/pattern-2.png")',
        }}
      >
        <div className="auto-container">
          <div className="sec-title light">
            <div className="title">Keynote Topics</div>
            <h2>Turning the Impossible into Possible</h2>
          </div>
          <div className="row clearfix">
            <div className="process-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="inner-box transition-300ms">
                <div className="icon transition-500ms">
                  <img src="/assets/css/images/medal.png" width="80" alt="" />
                </div>
                <h4>
                  <Link to="/">GOLD MEDAL RESILIENCE</Link>
                </h4>
                <div className="text">
                  The audience will learn tools to up their game in the face of
                  adversity by expanding their mindset to consider endless
                  possibilities. Learn how to set dream goals and be more
                  confident in stressful situations. My mom always told me,
                  “Andrew it’s not what happens to you it’s what you do about it
                  that determines the quality of your life”. What are you doing
                  to make positive strides with what you are doing? How do you
                  overcome obstacles and are you ready to commit to the pursuit
                  of your own gold medal moment?.
                </div>
              </div>
            </div>

            <div className="process-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="inner-box transition-300ms">
                <div className="icon transition-500ms">
                  <img
                    src="/assets/css/images/brainstorm.png"
                    width="80"
                    alt=""
                  />
                </div>
                <h4>
                  <Link to="/">Peak Performance Mindset</Link>
                </h4>
                <div className="text">
                  Despite our own personal circumstances we have the power
                  within to achieve success. Performance mindset is about
                  preparation and planning which is something we can all buy
                  into. Being mentally tough is an asset on the road to success.
                  Andrew will also share his 7 step process that helped him own
                  the podium.
                </div>{" "}
              </div>
            </div>

            <div className="process-block col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div className="inner-box transition-300ms">
                <div className="icon transition-500ms">
                  <img
                    src="/assets/css/images/hospital-bed.png"
                    width="80"
                    alt=""
                  />
                </div>
                <h4>
                  <Link to="/">
                    From Other<br></br> Side of Bed
                  </Link>
                </h4>
                <div className="text">
                  Have you ever thought you were down and out, and life was just
                  too much? The odds were stacked against you, and you didn’t
                  know which way to go? That was me when I was just a young boy
                  trying to battle cancer (35% chance to live) but I made it
                  through by focusing on what I could control and never giving
                  up hope no matter how bad it seemed. I was inspired by the
                  great care I received, so in turn, I want to inspire my
                  audiences to never settle for what life throws at you. I want
                  to share life from the other side of the bed. Want more,
                  demand better and never give up on your dreams.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Process;
