import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div>
      <div className="xs-sidebar-group info-group">
        <div className="xs-overlay xs-bg-black"></div>
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading">
              <Link to="/" className="close-side-widget">
                X
              </Link>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner">
                  <div className="logo">
                    <a href="index-2.html">
                      <img src="assets/images/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="content-box">
                    <h4>About Us</h4>
                    <p className="text">
                      I am glad that you have made it here to send a distress
                      signal, and inform the Senate that all on board killed.
                      Dantooine. I’m not going to Alderaan. I really got to go.
                      But that to me.
                    </p>
                    <Link to="/" className="theme-btn btn-style-one">
                      <span className="txt">Consultation</span>
                    </Link>
                  </div>
                  <div className="contact-info">
                    <h4>Contact Info</h4>
                    <ul className="list-style-one">
                      <li>
                        <span className="icon fa fa-location-arrow"></span>Chicago
                        12, Melborne City, USA
                      </li>
                      <li>
                        <span className="icon fa fa-phone"></span>(111) 111-111-1111
                      </li>
                      <li>
                        <span className="icon fa fa-envelope"></span>
                        avarton@gmail.com
                      </li>
                      <li>
                        <span className="icon fa fa-clock-o"></span>Week Days: 09.00
                        to 18.00 Sunday: Closed
                      </li>
                    </ul>
                  </div>
                  <ul className="social-box">
                    <li>
                      <Link
                        to="/"
                        className="fa fa-facebook-f"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        href="/"
                        className="fa fa-twitter"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        href="/"
                        className="fa fa-linkedin"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        href="/"
                        className="fa fa-youtube-play"
                      ></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
