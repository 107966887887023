import React from "react";
import { Link } from "react-router-dom";

function Featured() {
  return (
    <div id="media">
      <section className="course-section-two">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="title color-four">Featured</div>
            <h2>Featured Media</h2>
          </div>
          <div className="row clearfix">
            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-1.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://youtu.be/FCfVwg2yazY"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link target="_blank" to="https://youtu.be/FCfVwg2yazY">
                      Keynote Talk<br></br> April - 2017
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="150ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-2.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://youtu.be/zLsg9xfPNfU"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link target="_blank" to="https://youtu.be/zLsg9xfPNfU">
                      HSBC Keynote Inspirational Speech
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-3.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://youtu.be/nNuN1B2oG20"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link target="_blank" to="https://youtu.be/nNuN1B2oG20">
                      Paralympic and World Champion
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="450ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-4.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://youtu.be/6PC6_Jdztfg"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link target="_blank" to="https://youtu.be/6PC6_Jdztfg">
                      2000 Paralmpic Games 400 Medley Relay
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="450ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-5.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://www.youtube.com/watch?v=RD1FqnLBmAw"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link
                      target="_blank"
                      to="https://www.youtube.com/watch?v=RD1FqnLBmAw"
                    >
                      Andrew Haley on turning obstacles into opportunities
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="course-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInLeft"
                data-wow-delay="450ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="inner-column wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div
                    className="pattern-layer"
                    style={{
                      backgroundImage:
                        'url("assets/images/background/pattern-1.png")',
                    }}
                  ></div>
                  <div className="video-box">
                    <figure className="video-image">
                      <img
                        className="transition-500ms"
                        src="assets/images/background/video-6.png"
                        alt=""
                      />
                    </figure>
                    <a
                      href="https://www.youtube.com/watch?v=PhGnYjupBlY"
                      className="lightbox-image overlay-box"
                    >
                      <span className="sr-only">Youtube</span>
                    </a>
                  </div>
                </div>
                <div className="lower-content">
                  <h5>
                    <Link
                      to="https://www.youtube.com/watch?v=PhGnYjupBlY"
                      target="_blank"
                    >
                      Andrew Haley on the power of harnessing control and
                      embracing resilience
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Featured;
