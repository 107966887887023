import React from "react";

function Service() {
  return (
    <div>
      <section className="services-section mb-5">
          {/* <div className="sec-title">
            <div className="title color-two">Partners</div>
            <h2>Companies I have partnered with</h2>
          </div> */}
          <div className="auto-container">
            <div className="services-carousel owl-carousel owl-theme">
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image">
                    <a
                      href="https://www.specialolympics.ca/saskatchewan"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/specialolympics.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image">
                    <a
                      href="https://www.walmart.ca/en"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/walmart-logo.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.tevapharm.com/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/logo-teva.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.financialhorizons.com/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/finencial.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.rbcinsurance.com/personal.html"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/rbc-logo-shield.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.airbnb.ca/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/Airbnb.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.pearsoncanadaschool.com/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/person.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.danfoss.com/en/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/danfoss.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.manulife.ca/personal.html"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/manulife.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.sunlife.ca/en/"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/sunlife.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="service-block translate-top">
                <div className="inner-box">
                  <div className="image ">
                    <a
                      href="https://www.asahigroup-holdings.com/en/agechk_en/index.html"
                      target="_blank"
                      rel="noreferrer"
                      style={{display:'flex', justifyContent: 'center'}}
                    >
                      <img
                        className="transition-500ms"
                        src="assets/images/resource/asahi.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
}

export default Service;
