import React from "react";
import PDF from "./PDF";

function Hero() {
  return (
    <section className="main-hero">
      <div className="overlay"></div>
      <video autoPlay loop muted className="video-background">
        <source src="assets/video/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content px-md-0 px-5 pt-5 mt-5">
        <h2 className="mt-md-5" style={{ color: "var(--main-color)" }}>
          "ALLOW YOURSELF TO BE GREAT"
        </h2>
        <br />
        <h5 className="text-light fw-bolder text-center">
          <i>
            Strive for your best not only for yourself but also for your team.
          </i>
        </h5>
        <br />
        <p className="text-center container text-white ">
          Andrew Haley is a Keynote Speaker, Paralympic and World Champion and
          Cancer Survivor who shares his message about never giving up and
          always going after your own GOLD MEDAL MOMENT. His talks help people
          set a path to follow their dreams and find purpose in what they are
          doing so they can rise above others and find an edge in life.
        </p>
        <div class="auto-container bio-container mt-3">
          <div class="pdf-wrapper">
            <PDF />
          </div>
          <button className="bio-btn">
            <a href="mailto:andrew_haley@hotmail.com" className="bio-contact">Contact Andrew</a>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
