import React from "react";

function About() {
  return (
    <div id="about">
      <section className="about-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image" data-tilt data-tilt-max="3">
                  <img src="assets/images/resource/about-1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="content-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <div className="title">About Me</div>
                  <h2>
                    I help Companies and Teams elevete their Performance to find
                    their own gold medal moment.
                  </h2>
                  <div className="text">
                    I am on a journey to inspire and teach as many leaders as
                    possible to commit to their own GOLD MEDAL MOMENT. I want to
                    encourage my audience to never give up and strive to achieve
                    both personal and professional excellence that will help
                    guide them to reach their full potential. I’ve had many
                    setbacks in my life including losing part of my right leg as
                    a child to cancer and being given only a 35% chance to
                    survive. I didn’t want to just survive though, I wanted to
                    show people I could be something special despite my
                    circumstances. As a competitive swimmer I would become a
                    World and Paralympic champion and that eventually led to a
                    hall of fame career. <br />
                    How we show up every day is important to me, and I am fired
                    up when I can deliver tangible strategies to help my
                    audience pursue their own dreams. I learned that success is
                    built-on preparation and having a champion’s mindset
                    combined with controlling the controllable. This is
                    available to anyone if they want to win in life. My job is
                    to guide you to get there one day at a time.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
