import React from "react";
function Testmonial() {
  return (
    <div id="testimonials">
      <section className="testimonial-section">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="title color-four">Testimonials</div>
            <h2>
              Hear out what my clients <br /> say about me.
            </h2>
          </div>
          <div className="testimonial-carousel owl-carousel owl-theme">
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Andrew's  presentation was perfectly aligned with the theme
                    of our event. Our office has some of the top performing
                    individuals in the company, so they can appreciate the
                    effort Andrew put into reaching his goals and dreams."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Sunlife Financial</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Andrew is an excellent speaker and hi message certainly hit
                    the mark at our event. It was obvious the 400 plus members
                    we had in the audience agreed with our choice of presenter.
                    As one of the world's leading international banks we
                    certainly appreciate the effort it takes to
                    be among the best. "
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>HSBC </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    " Thank you so much for delivering such an impactful Keynote
                    Presentation at the MPI/ILEA ONE Conference in Calgary on
                    March 14th, 2017, and for making a special effort to travel
                    to Calgary for this conference. Your presentation was
                    dynamic, insightful and really touched everyone in the room
                    in different ways. Of course, no one in the audience was an
                    athlete – but you managed to tailor your keynote to a
                    diverse group of event professionals."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>MPI/ILEA</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    " Thank you for kicking off our 4th Annual Global
                    Pharmaceutical and Medical Meetings Summit. Our entire team
                    enjoyed working with you. You have a wonderful stage
                    presence and a great story to tell. You were very well
                    received by our attendees. It is always a great sign when
                    the delegates are talking about the opening keynote speaker
                    during the closing session. Thank you for helping to make
                    our conference a great success."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Senior Vice President, World Congress</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    " I had a pleasure to invite Andrew to our team building
                    unconference at Danfoss Drives. The audience consisted of
                    approximately 100 people scattered across entire world.
                    Andrew's passionate and inspiring speech about change and
                    overcoming difficulties has managed to reach all of the
                    participants. I am writing the recommendation few months
                    after the event but the impression and impact still remains
                    within the organization."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Katarzyna Beutnagel</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Providing strategic comms and change management support for
                    the advancement of healthcare in Nova Scotia November 20,
                    2019, Andrew was an engaging and inspirational guest during
                    our BioPort Atlantic Conference in 2019 where he was
                    interviewed live by the hosts who make up the widely popular
                    Sickboy Podcast. He helped us to promote the event and
                    played a critical role in its success. His drive and
                    positive mindset left our attendees feeling renewed and
                    inspired."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Shana Cristoferi</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Our organization, the Burlington Aquatic Devilrays, had the
                    opportunity to have Andrew speak to our swimmers and parents
                    in October 2019. Andrew presented material to both swimmers
                    and parents that was inspiring, informative and motivational
                    in nature. The swimmers were focused throughout the talk. He
                    kept them interested and captive with his speech. The
                    parents were enlightened by the talk as well. Andrew comes
                    across very well. He keeps the subject light enough to be
                    entertaining with jokes interspersed throughout, but also
                    deep enough that the swimmers were able to takeaway a great
                    deal. After the talk Andrew took the extra time to meet with
                    swimmers and parents individually and also pose for pictures
                    with the team and with individuals. He truly enjoys what he
                    does and he is fantastic at delivering what he says he will
                    deliver. Great job, would ask Andrew to our Club again! I
                    would highly recommend Andrew. "
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Catherine Colman</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Andrew is a fantastic motivational speaker for teenagers
                    and adults alike! We booked him to be our Keynote Speaker at
                    the annual Kiwanis Key Club Eastern Canada District
                    Convention in Niagara Falls in April 2019 and the feedback
                    we received was that he was "just the speaker who had the
                    right messages that the young people needed to hear". He was
                    funny, engaging and above all entertaining! We would book
                    him again in a heartbeat! Deborah Knight, District
                    Administrator, Key Clubs, Eastern Canada District Kiwanis
                    International"
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Deborah Knight</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    " Thank you for kicking off our 4th Annual Global
                    Pharmaceutical and Medical Meetings Summit. Our entire team
                    enjoyed working with you. You have a wonderful stage
                    presence and a great story to tell. You were very well
                    received by our attendees. It is always a great sign when
                    the delegates are talking about the opening keynote speaker
                    during the closing session. Thank you for helping to make
                    our conference a great success."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Senior Vice President, World Congress</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I had a chance to invite Andrew to one of our office
                    meeting. Andrew was our key speaker. The history of his
                    life, his ups and downs in his life are so touching that it
                    makes you cry and truly open your eyes to see life from a
                    different angle. His main message was how to get up after
                    you fall down, it was a message that makes you to take an
                    action in your personal life and your business immediately.
                    I hope you also get the chance to meet this extraordinary
                    man."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5> Djebran(Jeb) Mehdawi (CLU)</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "We had the absolute pleasure of having Andrew join us as a
                    keynote speaker at our Annual National Meeting. Andrew
                    brings a wealth of expertise, experience and knowledge to
                    the table, and delivered a dynamic and engaging session that
                    really resonated with our group. His ability to connect with
                    our team while imparting valuable insights through powerful
                    storytelling was a real asset to our meeting. We are
                    grateful for the time, care and attention that Andrew took
                    to learn about our business, and tailor his message to our
                    specific needs. He really went above and beyond. I would
                    highly recommend Andrew to any organization seeking an
                    inspiring speaker."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>
                      Regional Marketing Manager, Eastern Canada Asahi Canada
                    </h5>
                  </div>
                </div>
              </div>
            </div>{" "}
            *
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I had a chance to invite Andrew to one of our office
                    meeting. Andrew was our key speaker. The history of his
                    life, his ups and downs in his life are so touching that it
                    makes you cry and truly open your eyes to see life from a
                    different angle. His main message was how to get up after
                    you fall down, it was a message that makes you to take an
                    action in your personal life and your business immediately.
                    I hope you also get the chance to meet this extraordinary
                    man."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Djebran Jeb Mehdawi</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Andrew Haley served as the key note speaker for the
                    Tribunal's 2017 Diversity and Inclusion training program.
                    Andrew is an exceptionally engaging and inspiring orator who
                    captivated the audience with his touching account of his
                    perseverance and optimism in the face of adversity. His life
                    journey and accomplishments are a testament to the power of
                    determination and resilience. His passion and commitment to
                    transform a physical disability into an opportunity to
                    compete and succeed on the world stage of sport encourages
                    and inspires those around him to embrace their own
                    differences, and be their own life champions. Andrew's key
                    note address was a significant factor in the success of our
                    program. "
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Lynn Telalidis</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Thank you so much for delivering such an impactful Keynote
                    Presentation at the MPI/ILEA ONE Conference in Calgary on
                    March 14th, 2017, and for making a special effort to travel
                    to Calgary for this conference. Your presentation was
                    dynamic, insightful and really touched everyone in the room
                    in different ways. Of course, no one in the audience was an
                    athlete – but you managed to tailor your keynote to a
                    diverse group of event professionals, working in a variety
                    of disciplines, and made it meaningful and inspirational to
                    each of us. I found myself so proud of your accomplishment
                    at the 2000 Paralympic Games, I had to wipe a tear at the
                    end of that short video – talk about impact! I am sure you
                    will bring great benefit, experience and inspiration to
                    vastly different audiences and I would highly recommend you
                    for other speaking opportunities. I look forward to the day
                    that we can work together again. "
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Pam McCarthy</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Thank you for kicking off our 4th Annual Global
                    Pharmaceutical and Medical Meetings Summit. Our entire team
                    enjoyed working with you. You have a wonderful stage
                    presence and a great story to tell. You were very well
                    received by our attendees. It is always a great sign when
                    the delegates are talking about the opening keynote speaker
                    during the closing session. Thank you for helping to make
                    our conference a great success. "
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Courtney Richman</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "Andrew is a great speaker with a terrific message on
                    courage and determination. He kept the audience engaged with
                    his humor and upbeat message. I highly recommend him!"
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Judy Benaroche Johnson</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I was very fortunate to see Andrew speak recently. I run a
                    telecom sales team and found that Andrew's story of both
                    hardship and success was a lesson for all of us. The
                    overwhelming feedback I heard from my team was that Andrew
                    made them really think about how they approach the daily
                    challenges they face and were inspired to set aside any of
                    the typical excuses we use as a barrier to success. Thank
                    you for being an inspiration to all of us, Andrew!"
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Joe Quaresima</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I heard Andrew Speak at Allstreams's annual sales kickoff.
                    Andrew is a very personable speaker with a big inspiring
                    story to tell. He delivers his highly inspirational message
                    in a way that that we can all relate to and see how we
                    ourselves can focus on what we want to achieve in our lives:
                    so that we "Normal People" can also do great things
                    ourselves. I have let go of a limiting belief I didn't even
                    know I had around competitiveness, and am more focused with
                    clarity on what's important to me, and feel better as a
                    human being."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Robert Sinkus</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I had the pleasure to have Andrew speak about his swimming
                    accomplishments and maintaining a positive outlook at our
                    YMCA. Andrew spoke with passion and enthusiasm, easily
                    establishing a rapport with audience members young and old
                    though his candid demeanor. I am happy to recommend Andrew
                    as an inspirational speaker."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Taylor Weber</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I had an opportunity to hire Andrew for two events recently
                    and was very impressed. On both occasions, our guests were
                    very moved by his message and were inspired to improve.
                    Andrew was very accommodating throughout the entire process
                    and tried to make the events as successful as possible for
                    us. Would certainly recommend Andrew for any educational or
                    client appreciation event in the future."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Oliver Lee</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I had the pleasure of meeting world champion and Paralympic
                    swimmer Andrew Haley through the work that he does for Clean
                    Air Champions, an organization whose mission is to improve
                    air quality by working with high performance athletes to
                    educate and inspire Canadians. I recommend Andrew for public
                    speaking engagements at schools and businesses because he
                    delivers a powerful message that resonates well with these
                    audiences. Andrew is a perfect example of what you can
                    accomplish through sheer willpower. His ability to bring the
                    same level of energy and determination to the workplace has
                    made him a top sales producer."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Sina S. Amiri</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-block">
              <div className="inner-box">
                <div className="upper-box">
                  <span className="quote-icon flaticon-quote-3"></span>
                  <div className="rating">
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                    <span className="fa fa-star"></span>
                  </div>
                  <div className="text">
                    "I have known Andrew for over five years as a member of our
                    national charity Clean Air Champions. Andrew has spoken on
                    behalf of CAC to a wide range of audiences from young
                    students, media, public, and corporate employees. He has
                    consistently been praised and ranked 4 or higher (out of 5)
                    in all our client surveys.Andrew is passionate about what he
                    takes and is someone I would have no hesitation in
                    recommending. We greatly value Andrew's contribution to the
                    work we do and are proud to say he is a Champion."
                  </div>
                </div>
                <div className="lower-box">
                  <div className="">
                    <h5>Deirdre Laframboise</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Testmonial;
