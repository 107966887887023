import React from "react";

function News() {
  return (
    <div id="blog">
      <section className="news-section-two">
        <div className="auto-container">
          <div className="sec-title">
            <div
              className="title color-two"
              style={{ backgroundColor: "var(--main-color-two)" }}
            >
              News
            </div>
            <h2>Blogs and Insights</h2>
          </div>
          <div className="row clearfix">
            <div className="news-block-four col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="/blog/finding-your-gold-medal-moment">
                    <img
                      className="transition-500ms"
                      src="assets/images/background/2.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="/blog/finding-your-gold-medal-moment"
                    className="arrow flaticon-right-arrow-1"
                  >
                    <span className="sr-only">Blog</span>
                  </a>
                  <a
                    href="/blog/finding-your-gold-medal-moment"
                    className="read-more"
                  >
                    read more
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-info">
                    <li>Feb 20, 2024</li>
                    <li>
                      <a href="/blog/finding-your-gold-medal-moment">
                        Andrew Haley
                      </a>
                    </li>
                  </ul>
                  <h4>
                    <a href="/blog/finding-your-gold-medal-moment">
                      Finding Your Gold Medal Moment
                    </a>
                  </h4>
                </div>
              </div>
            </div>

            {/* <div className="news-block-four col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="blog-detail.html">
                    <img
                      className="transition-500ms"
                      src="assets/images/resource/news-8.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="blog-detail.html"
                    className="arrow flaticon-right-arrow-1"
                  ></a>
                  <a href="blog-detail.html" className="read-more">
                    read more
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-info">
                    <li>January 21, 2022</li>
                    <li>
                      <a href="blog-detail.html">Consult</a>
                    </li>
                  </ul>
                  <h4>
                    <a href="blog-detail.html">
                      To carry out put into action performto implement.
                    </a>
                  </h4>
                </div>
              </div>
            </div>

            <div className="news-block-four col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="blog-detail.html">
                    <img
                      className="transition-500ms"
                      src="assets/images/resource/news-9.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="blog-detail.html"
                    className="arrow flaticon-right-arrow-1"
                  ></a>
                  <a href="blog-detail.html" className="read-more">
                    read more
                  </a>
                </div>
                <div className="lower-content">
                  <ul className="post-info">
                    <li>January 21, 2022</li>
                    <li>
                      <a href="blog-detail.html">Consult</a>
                    </li>
                  </ul>
                  <h4>
                    <a href="blog-detail.html">
                      To carry out put into action performto implement.
                    </a>
                  </h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default News;
