import React from 'react';

class PDF extends React.Component {
  handleView = () => {
    const pdfUrl = '/assets/ANDREW.pdf';

    window.open(pdfUrl, '_blank');
  };

  render() {
    return (
      <>
      {/* <button className="theme-btn btn-style-three" >
        View Bio
      </button> */}
      <button onClick={this.handleView}  className="bio-button">View Bio</button>
      </>
    );
  }
}

export default PDF;
