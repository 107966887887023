import React from "react";

function Header() {
  return (
    <div>
      <header className="main-header fixed-header">
        <div className="header-top">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="top-right pull-right clearfix">
                <ul className="social-box">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="fa fa-facebook-f"
                      target="_blank"
                      rel="noopener noreferrer"
                    ><span className="sr-only">Facebook</span></a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/"
                      className="fa fa-twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    ><span className="sr-only">Twitter</span></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      className="fa fa-instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    ><span className="sr-only">Instagram</span></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/ahaley1/"
                      className="fa fa-linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    ><span className="sr-only">LinkedIn</span></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@ahaleyca1"
                      className="fa fa-youtube-play"
                      target="_blank"
                      rel="noopener noreferrer"
                    ><span className="sr-only">Youtube</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a href="/" title="">
                <img src="../assets/images/logo.png" width={150} alt="logo" />
              </a>
            </div>
            <div className="pull-right">
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>

                <div
                  className="navbar-collapse collapse clearfix "
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix ms-auto pull-right">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#about">About</a>
                    </li>
                    <li>
                      <a href="/#media">Media</a>
                    </li>
                    <li>
                      <a href="/#speaking">Speaking</a>
                    </li>
                    <li>
                      <a href="/#testimonials">Testimonials</a>
                    </li>
                    <li>
                      <a href="/blogs">Blogs</a>
                    </li>
                    <li>
                      <a href="mailto: andrew_haley@hotmail.com">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>

              <div className="mobile-nav-toggler">
                <span className="icon flaticon-menu-1"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <span className="icon flaticon-multiply"></span>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <a href="/">
                <img src="../assets/images/logo.png" width={80} alt="logo" />
              </a>
            </div>
            <div className="menu-outer">
              <ul className="navigation clearfix ms-auto pull-right">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  <a href="/#media">Media</a>
                </li>
                <li>
                  <a href="/#speaking">Speaking</a>
                </li>
                <li>
                  <a href="/#testimonials">Testimonials</a>
                </li>
                <li>
                  <a href="/blogs">Blogs</a>
                </li>
                <li>
                  <a href="mailto:andrew_haley@hotmail.com">Contact Andrew</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
