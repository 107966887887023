import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'
import Main from '../Components/Home/Main'
import Loader from '../Components/Common/Loader'


function Home() {
  return (
    <div>
      <Loader/>
      <Header/>
      <Main/>
      <Footer/>
    </div>
  )
}

export default Home
