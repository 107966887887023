import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'
import Main from '../Components/Blogs/Main'


function Blogs() {
  return (
    <div>
      <Header/>
      <Main/>
      <Footer/>
    </div>
  )
}

export default Blogs
