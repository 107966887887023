import React from "react";

function GoldmedalMoment() {
  return (
    <div>
      <div className="news-block-two">
        <div className="inner-box">
          <div className="image">
            <a href="/blog/finding-your-gold-medal-moment">
              <img
                className="transition-500ms"
                src="assets/images/background/2.jpg"
                alt=""
              />
            </a>
          </div>
          <div className="lower-content">
            <div className="category">Business</div>
            <div className="author-box">
              <div className="box-inner">by Andrew Haley</div>
            </div>
            <h3>
              <a href="/blog/finding-your-gold-medal-moment">Finding Your Gold Medal Moment</a>
            </h3>
            <div className="text">
              In a world that often feels like it's spinning faster than we can
              keep up, it's easy to lose sight of what truly matters.
            </div>
            <ul className="post-meta">
              <li>
                <span className="icon fa fa-calendar"></span>20th Feb 2024
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoldmedalMoment;
