import React from "react";
import "react-toastify/dist/ReactToastify.css";

function Course() {
  return (
    <div>
      <section className="course-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="title-column col-lg-6 col-md-12 col-sm-12">
              <div className="">
                <div className="sec-title">
                  <div className="title color-three">Demo</div>
                  <h2>Demo reel</h2>
                  <div className="text">
                    As a speaker and mentor, Andrew is dedicated to equipping
                    his audience with tangible strategies to navigate life's
                    challenges and pursue excellence. With every interaction, he
                    strives to impart invaluable lessons learned from his own
                    journey, guiding others on a path toward personal and
                    professional fulfillment, one day at a time. Andrew's impact
                    transcends boundaries, leaving a lasting impression on all
                    those fortunate enough to cross paths with him.
                  </div>
                  <div className="text" style={{ color: "var(--main-color)" }}>
                    Click play to watch Andrew Haley&apos;s inspirational demo reel.
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="video-column col-lg-6 col-md-12 col-sm-12">
              <div
                className="inner-column wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div
                  className="pattern-layer"
                  style={{
                    backgroundImage:
                      'url("assets/images/background/pattern-1.png")',
                  }}
                ></div>
                <div className="video-box">
                  <figure className="video-image">
                    <img
                      className="transition-500ms"
                      src="assets/images/background/news.png"
                      alt=""
                    />
                  </figure>
                  <a
                    href="https://youtu.be/9P5EhcHwJF8"
                    className="lightbox-image overlay-box"
                  >
                    <span className="transition-900ms">
                      Play<i className="ripple"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Course;
