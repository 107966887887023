import React from "react";

function Hero() {
  return (
    <div>
      <section
        className="page-title pb-5"
        style={{
          backgroundImage: "url(../assets/images/background/2.jpg)",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="auto-container">
          <div className="content">
            <div className="text">Welcome to our company</div>
            <h1>News Feeds</h1>
          </div>
          <div className="breadcrumb-outer">
            <ul className="page-breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Blog</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
